import * as Sentry from "@sentry/astro";

Sentry.init({
  dsn: "https://f126eafd34e405d49ef540347feffc64@o190059.ingest.us.sentry.io/4507736330207232",
  debug: false,
  environment: import.meta.env.PUBLIC_VERCEL_ENV,
  release: "7a15e4d62bb20d0e034c3e7eb4b6dbb4a3260edf",
  tracesSampleRate: 1,
  integrations: [Sentry.browserTracingIntegration(), Sentry.replayIntegration()],
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1,
});